

// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap');


if (document.readyState !== 'loading') {
    console.log('document is already ready, just execute code here');
    setTimeout(iniciar, 2500);
} else document.addEventListener('DOMContentLoaded', (event) => {
    console.log('loaded')
    setTimeout(iniciar, 2500);
});

document.addEventListener('scroll', initOnEvent);
document.addEventListener('mousemove', initOnEvent);
document.addEventListener('touchstart', initOnEvent);

function initOnEvent(event) {
    iniciar();
    event.currentTarget.removeEventListener(event.type, initOnEvent); // remove the event listener that got triggered
}

async function initGtm(w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
        'gtm.start':
            new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);

        
        
}

async function initPixel(f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = []; t = b.createElement(e); t.async = !0;
    t.src = v; s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);


    fbq('init', '1002835093737246');
    fbq('track', 'PageView');
}



async function iniciar() {
    if (window.DidInit) {
        return false;
    }
    console.log('Iniciar libs')
    window.DidInit = true;

    // try {
    //     initPixel(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    // } catch {
    //     console.log('Erro ao iniciar o pixel')
    // }

    initGtm(window, document, 'script', 'dataLayer', 'GTM-WZTZ922');

}
